<template>
  <WithReportAssets :asset-type="assetType" :sort-by="'TABLE_ORDER_NUMBER'">
    <div
      slot-scope="{ reportAssets, areAssignmentsLoading, reportAssetsError }"
    >
      <WithAllowedJobAssets
        v-if="getLocationJobId"
        :asset-type="costCodeAssetType"
        :job-id="getLocationJobId"
        :is-for-picklist="true"
      >
        <div slot-scope="{ assets, areAssetsLoading, allowedAssetsError }">
          <DailyReportAssignmentCard
            id="pay-work"
            title="Requisition Items"
            :assets="reportAssets"
            :asset-type="assetType"
            :fields="fields"
            :loading="areAssignmentsLoading || areAssetsLoading"
            :error="reportAssetsError || allowedAssetsError"
            :defaults="{
              assetNameKey: 'COST_CODE',
              picklistKey: 'COST_CODE',
              assgntKey: 'COST_CODE',
            }"
            @reloadCard="reloadCard"
          >
            <template
              slot-scope="{
                asset,
                draft,
                updateAssetAction,
                startSaveTimer,
                pauseSaveTimer,
              }"
            >
              <AssignmentRequisitionItem
                :pickable-items="assets"
                :asset="asset"
                :draft="draft"
                @update="updateAssetAction"
                @startSaveTimer="startSaveTimer"
                @picking="pauseSaveTimer"
                @donePicking="startSaveTimer"
              />
            </template>
            <template
              v-slot:add-button="{ addAction, isAdding, loadingDefaults }"
            >
              <BaseButton
                md-icon="control_point"
                class="no-print add-record-btn"
                color="blue"
                :loading="isAdding"
                :disabled="loadingDefaults"
                @click="addItemHandler(reportAssets, addAction)"
                >New Requisition Item</BaseButton
              >
            </template>
          </DailyReportAssignmentCard>
        </div>
      </WithAllowedJobAssets>
    </div>
  </WithReportAssets>
</template>

<script>
import { AssetTypes, HumanFields } from '@constants/knack'
import WithReportAssets from '@dataProviders/WithReportAssets'
import WithAllowedJobAssets from '@dataProviders/WithAllowedJobAssets'

import { mapGetters } from 'vuex'

import DailyReportAssignmentCard from '@dailyReport/DailyReportAssignmentCard'
import AssignmentRequisitionItem from '@dailyReport/AssignmentRequisitionItem'

export default {
  name: 'CardRequisitionItems',
  components: {
    WithReportAssets,
    WithAllowedJobAssets,
    DailyReportAssignmentCard,
    AssignmentRequisitionItem,
  },
  data() {
    return {
      assetType: AssetTypes.REQUISITION_ITEMS,
      costCodeAssetType: AssetTypes.COST_CODES,
      fields: [
        {
          title: 'Pay/Work Item',
          required: true,
        },
        { title: 'Unit Type' },
        { title: 'Quantity Completed' },
        { title: 'Completion Notes' },
      ],
    }
  },
  computed: {
    ...mapGetters(['getLocationJobId', 'getLocation', 'getTheReport']),
    jobCostCodeFilters() {
      return [
        {
          field: HumanFields.JOB_COST_CODES.HIDE_FROM_WORK_ITEM_PICKLIST,
          operator: 'is not',
          value: 'True',
        },
        {
          field: HumanFields.JOB_COST_CODES.JOB,
          operator: 'is',
          value: this.getLocationJobId,
        },
      ]
    }, // jobCostCodeFilters
  }, // computed
  methods: {
    addItemHandler(reportAssets, addAction) {
      let orderNumber = Math.max(
        ...reportAssets.map((asset) => asset.TABLE_ORDER_NUMBER)
      )
      orderNumber++

      addAction({
        TABLE_ORDER_NUMBER: orderNumber,
        LOCATION: this.getLocation.ID,
      })
    }, // addItemHandler
    reloadCard() {
      console.log('reloadCard')
      let currentAssetType = this.assetType
      let currentCostCodeAssetType = this.costCodeAssetType
      this.assetType = ''
      this.costCodeAssetType = ''
      this.$nextTick(() => {
        this.assetType = currentAssetType
        this.costCodeAssetType = currentCostCodeAssetType
      })
    },
  }, // methods
}
</script>
