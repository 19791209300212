var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VLayout',{staticClass:"equipment-paywork-item",attrs:{"row":"","wrap":""}},[_c('VFlex',{class:[_vm.flexStyles, 'daily-list-item--title material-picklist']},[_c('BasePicklist',_vm._g({attrs:{"disabled":_vm.disabled,"title":_vm.asset.PAYWORK_ITEM_NAME,"value":_vm.asset.PAYWORK_ITEM_NAME,"items":_vm.pickableItems,"hint":"Pay/Work Item","item-text":"PAYWORK_ITEM_NAME","rules":[(v) => !!v || 'You must select a Pay/Work Item']},on:{"input":function($event){_vm.$emit('update', {
          PAYWORK_ITEM: [
            {
              id: $event,
              identifier: _vm.pickableItems.find((item) => item.ID == $event)
                .PAYWORK_ITEM_NAME,
            },
          ],
        })}}},_vm.$listeners))],1),_c('VFlex',{class:_vm.flexStyles},[_c('ListItemDisplayOnly',{attrs:{"value":_vm.unitType,"hint":"Unit Type"}})],1),_c('VFlex',{class:_vm.flexStyles},[_c('ListItemText',{attrs:{"disabled":_vm.disabled,"value":_vm.asset.QUANTITY_COMPLETED,"hint":"Quantity Completed"},on:{"input":function($event){return _vm.$emit('update', { QUANTITY_COMPLETED: $event })},"focus":function($event){return _vm.$emit('startSaveTimer')}}})],1),_c('VFlex',{class:_vm.flexStyles},[_c('ListItemText',{attrs:{"disabled":_vm.disabled,"value":_vm.asset.COMPLETION_NOTES,"hint":"Completion Notes"},on:{"input":function($event){return _vm.$emit('update', { COMPLETION_NOTES: $event })},"focus":function($event){return _vm.$emit('startSaveTimer')}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }