<template>
  <DailyNotesCard
    id="foremans-notes"
    title="Foreman's Notes"
    :loading="loading"
  >
    <BaseTextarea
      :value="report.DESCRIPTION_OF_WORK_COMPLETED_TODAY"
      :readonly="locked || loading"
      clearable
      label="Describe in Detail the Work Completed Today"
      :rules="[
        (v) =>
          isReportScheduledStatus ||
          v.length >= 5 ||
          'Please enter a description',
      ]"
      v-on="$listeners"
      @input="
        updateReport(
          { DESCRIPTION_OF_WORK_COMPLETED_TODAY: $event },
          'Describe in Detail the Work Completed Today'
        )
      "
      @focus="$emit('startSaveTimer')"
    />

    <BaseTextarea
      :value="report.FOREMANS_NOTES"
      :readonly="locked || loading"
      label="Foreman's Notes"
      clearable
      v-on="$listeners"
      @input="updateReport({ FOREMANS_NOTES: $event }, `Foreman's Notes`)"
      @focus="$emit('startSaveTimer')"
    />
    <BaseTextarea
      :value="report.PAYROLL_NOTES"
      :readonly="locked || loading"
      label="Payroll Notes"
      clearable
      v-on="$listeners"
      @input="updateReport({ PAYROLL_NOTES: $event }, `Payroll Notes`)"
      @focus="$emit('startSaveTimer')"
    />
    <ListItemCheckbox
      :value="hasIncidentOnSite"
      :readonly="locked || loading"
      force-show-label
      inline
      color="teal"
      label="Accidents/Incidents Today?"
      @change="
        updateReport(
          { INCIDENT_ON_SITE: !!$event },
          'Accidents/Incidents Today?'
        )
      "
    />
    <transition name="fade-fast">
      <BaseTextarea
        v-if="hasIncidentOnSite"
        :value="report.DESCRIPTION_OF_ANY_ACCIDENTSINCIDENTS_TODAY"
        :readonly="locked || loading"
        label="Describe any Accidents/Incidents"
        v-on="$listeners"
        @input="
          updateReport(
            {
              DESCRIPTION_OF_ANY_ACCIDENTSINCIDENTS_TODAY: $event,
            },
            'Describe any Accidents/Incidents'
          )
        "
        @focus="$emit('startSaveTimer')"
      />
    </transition>
    <transition name="fade-fast">
      <ListItemCheckbox
        v-if="hasIncidentOnSite"
        :value="isIncidentReportComplete"
        :readonly="locked || loading"
        force-show-label
        inline
        color="teal"
        label="Has an incident report been submitted?"
        @change="
          updateReport(
            { INCIDENT_REPORT_COMPLETED: !!$event },
            'Has an incident report been submitted?'
          )
        "
      />
    </transition>
    <ListItemCheckbox
      :value="hasChangeOfConditions"
      :readonly="locked || loading"
      force-show-label
      inline
      color="teal"
      label="Change of conditions?"
      @change="
        updateReport(
          { CHANGE_OF_CONDITIONS: !!$event },
          'Change of conditions?'
        )
      "
    />
    <ListItemCheckbox
      :value="areJobPhotosUploaded"
      :readonly="locked || loading"
      force-show-label
      inline
      color="teal"
      label="Job Photos Uploaded?"
      @change="
        updateReport({ JOB_PHOTOS_UPLOADED: !!$event }, 'Job Photos Uploaded?')
      "
    />
    <ListItemCheckbox
      :value="areAsBuiltsUploaded"
      :readonly="locked || loading"
      force-show-label
      inline
      color="teal"
      label="As-built Uploaded?"
      @change="
        updateReport({ ASBUILT_UPLOADED: !!$event }, 'As-built Uploaded?')
      "
    />
    <BaseInput
      v-if="hasChangeOfConditions"
      :readonly="locked || loading"
      :value="report.CHANGE_OF_CONDITIONS_NOTES"
      label="Change of Conditions Notes"
      v-on="$listeners"
      @input="
        updateReport(
          {
            CHANGE_OF_CONDITIONS_NOTES: $event,
          },
          'Change of Conditions Notes'
        )
      "
      @focus="$emit('startSaveTimer')"
    />

    <VLayout v-if="showMarkAsCompleteButton" row class="no-print">
      <BaseButton
        class="ml-0"
        :disabled="isMarkedAsComplete"
        small
        color="blue"
        @click="$emit('markLocationComplete')"
        >{{
          isMarkedAsComplete
            ? 'Location is Marked As Complete'
            : 'Mark this Location As Complete'
        }}</BaseButton
      >
    </VLayout>
    <VLayout
      v-if="!isMarkedAsComplete"
      row
      class="no-print text-xs-left"
      style="max-width:400px;line-height:1.3;"
    >
      <p
        >Only click "Mark Location As Complete" if this Job is 100% complete
        except for Paving and Cleanup</p
      >
    </VLayout>
    <VLayout row class="no-print">
      <FileStorageLinkButton :location="getLocation" />
    </VLayout>
  </DailyNotesCard>
</template>

<script>
import DailyNotesCard from '@dailyReport/DailyNotesCard'
import FileStorageLinkButton from '@dailyReport/FileStorageLinkButton'
import { LocationStatuses, ReportStatuses } from '@constants/knack'
import { mapGetters } from 'vuex'

export default {
  name: 'CardForemansNotes',
  components: { DailyNotesCard, FileStorageLinkButton },
  props: {
    report: {
      type: Object,
      required: true,
      default: () => {},
    },
    loading: { type: Boolean, default: false },
    locked: { type: Boolean, default: false },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['getLocation', 'getGoogleDriveLink']),
    hasChangeOfConditions: {
      get() {
        return !!this.report.CHANGE_OF_CONDITIONS
      },
      set() {},
    },
    hasIncidentOnSite: {
      get() {
        return !!this.report.INCIDENT_ON_SITE
      },
      set() {},
    },
    isIncidentReportComplete: {
      get() {
        return !!this.report.INCIDENT_REPORT_COMPLETED
      },
      set() {},
    },
    areJobPhotosUploaded: {
      get() {
        return !!this.report.JOB_PHOTOS_UPLOADED
      },
      set() {},
    },
    areAsBuiltsUploaded: {
      get() {
        return !!this.report.ASBUILT_UPLOADED
      },
      set() {},
    },
    isReportScheduledStatus() {
      return this.report.REPORT_STATUS === ReportStatuses.SCHEDULED
    },
    isMarkedAsComplete: {
      get() {
        return [
          LocationStatuses.REPORTED_AS_COMPLETE_BY_FOREMAN,
          LocationStatuses.COMPLETE,
        ].includes(this.getLocation.STATUS)
      },
      set() {},
    },
    showMarkAsCompleteButton() {
      return ['Micro', 'Micro Repair', 'Emergency'].includes(
        this.report.JOB_TYPE
      )
    }, // showMarkAsCompleteButton
  }, // computed
  methods: {
    updateReport(updates, label) {
      if (!label) {
        label = Object.keys(updates)[0]
      }
      this.$emit('updateReport', {
        updates,
        label,
      })
    }, // updateReport
  },
}
</script>
