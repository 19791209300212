<template>
  <WithReportAssets :asset-type="assetType" :sort-by="'TABLE_ORDER_NUMBER'">
    <div
      slot-scope="{ reportAssets, areAssignmentsLoading, reportAssetsError }"
    >
      <WithAllAssetsOfType
        asset-type="paywork_item"
        :additional-filters="filterByJobTypePicklist"
        filter-match-type="or"
      >
        <div slot-scope="{ allAssets, areAssetsLoading }">
          <DailyReportAssignmentCard
            id="pay-work"
            title="Pay/Work Items"
            :assets="reportAssets"
            :asset-type="assetType"
            :fields="fields"
            :loading="areAssignmentsLoading || areAssetsLoading"
            :error="reportAssetsError"
            :defaults="{
              assetNameKey: 'PAYWORK_ITEM_NAME',
              picklistKey: 'PAYWORK_ITEM_NAME',
              assgntKey: 'PAYWORK_ITEM',
            }"
            @reloadCard="reloadCard"
          >
            <template
              slot-scope="{
                asset,
                draft,
                updateAssetAction,
                startSaveTimer,
                pauseSaveTimer,
              }"
            >
              <AssignmentPayWorkItem
                :pickable-items="allAssets"
                :asset="asset"
                :draft="draft"
                @update="updateAssetAction"
                @startSaveTimer="startSaveTimer"
                @picking="pauseSaveTimer"
                @donePicking="startSaveTimer"
              />
            </template>
            <template
              v-slot:add-button="{ addAction, isAdding, loadingDefaults }"
            >
              <BaseButton
                md-icon="control_point"
                class="no-print add-record-btn"
                color="blue"
                :loading="isAdding"
                :disabled="loadingDefaults"
                @click="addItemHandler(reportAssets, addAction)"
                >New Pay/Work Item</BaseButton
              >
            </template>
          </DailyReportAssignmentCard>
        </div>
      </WithAllAssetsOfType>
    </div>
  </WithReportAssets>
</template>

<script>
import { AssetTypes, HumanFields, AssetObjectNames } from '@constants/knack'
import WithReportAssets from '@dataProviders/WithReportAssets'
import WithAllAssetsOfType from '@dataProviders/WithAllAssetsOfType'

import { mapGetters } from 'vuex'

import DailyReportAssignmentCard from '@dailyReport/DailyReportAssignmentCard'
import AssignmentPayWorkItem from '@dailyReport/AssignmentPayWorkItem'

export default {
  name: 'CardPayWorkItem',
  components: {
    WithReportAssets,
    WithAllAssetsOfType,
    DailyReportAssignmentCard,
    AssignmentPayWorkItem,
  },
  data() {
    return {
      assetType: AssetTypes.PAYWORK_ITEM,
      fields: [
        {
          title: 'Pay/Work Item',
          required: true,
        },
        { title: 'Unit Type' },
        { title: 'Quantity Completed' },
        { title: 'Completion Notes' },
      ],
    }
  },
  computed: {
    ...mapGetters(['getTheReport', 'getLocation']),
    filterByJobTypePicklist() {
      if (!this.assetType) return []

      let locationJobType = this.getTheReport.JOB_TYPE
      let jobTypeParts = locationJobType.split(', ')
      let jobTypeRules = jobTypeParts.map((jobType) => ({
        field:
          HumanFields[AssetObjectNames[this.assetType]]
            .INCLUDE_IN_JOB_TYPE_PICKLIST,
        operator: 'contains',
        value: jobType,
      }))

      return jobTypeRules
    }, // filterByJobTypePicklist
  },
  methods: {
    reloadCard() {
      let currentAssetType = this.assetType
      this.assetType = ''
      this.$nextTick(() => {
        this.assetType = currentAssetType
      })
    }, // reloadCard
    addItemHandler(reportAssets, addAction) {
      let orderNumber = Math.max(
        ...reportAssets.map((asset) => asset.TABLE_ORDER_NUMBER)
      )
      orderNumber++

      addAction({
        TABLE_ORDER_NUMBER: orderNumber,
        LOCATION: this.getLocation.ID,
      })
    }, // addItemHandler
  },
}
</script>
