<template>
  <WithReportAssets :asset-type="assetType" :sort-by="'TABLE_ORDER_NUMBER'">
    <div
      slot-scope="{ reportAssets, areAssignmentsLoading, reportAssetsError }"
    >
      <DailyReportAssignmentCard
        id="manpower"
        title="Manpower"
        :assets="sortByLaborerType(reportAssets)"
        :asset-type="assetType"
        :loading="areAssignmentsLoading"
        :error="reportAssetsError"
        :fields="fieldsWithClasses"
        :can-add="false"
        :can-remove="false"
        :defaults="{
          assetNameKey: 'LABORER_NAME',
          picklistKey: 'LABORER_NAME',
          assgntKey: 'LABORER_NAME',
        }"
        :is-shift-items-mode-active="isShiftItemsModeActive"
        :is-multi-split-on="isMultiSplitOn"
        :allows-shift-items="true"
        @toggleMultiSplit="isMultiSplitOn = !isMultiSplitOn"
        @toggleShiftItemsMode="toggleShiftItemsMode"
        @reloadCard="reloadCard"
      >
        <template v-slot:header="{ title, assets }">
          <VFlex
            >{{ title }} ({{ assets.length }})
            <BaseButton
              v-if="assetType"
              outline
              small
              color="blue"
              class="no-print"
              :to="{
                name: 'Dispatcher',
                params: { date: reportDate, assetType },
              }"
              >View in Dispatcher</BaseButton
            >
            <span class="print-only align-self-center d-inline-block ml-2">
              - All Workers Must Show up 15 Minutes Before Start Time!</span
            >
          </VFlex>
        </template>
        <!-- Default Slot is wrapped by AssignmentItem -->
        <template
          slot-scope="{
            asset,
            draft,
            updateAssetAction,
            startSaveTimer,
            pauseSaveTimer,
          }"
        >
          <AssignmentManpower
            :asset="asset"
            :draft="draft"
            :start-locations="startLocations"
            :is-shift-items-mode-active="isShiftItemsModeActive"
            :is-multi-split-on="isMultiSplitOn"
            @update="updateAssetAction"
            @picking="pauseSaveTimer"
            @startSaveTimer="startSaveTimer()"
            @donePicking="startSaveTimer()"
          />
        </template>
      </DailyReportAssignmentCard>
    </div>
  </WithReportAssets>
</template>

<script>
import dayjs from '@utils/dayjsCustomParse'
import _sortBy from 'lodash/sortBy'
import _get from 'lodash/get'
import { mapGetters, mapActions } from 'vuex'
import { AssetTypes, LaborerTypes } from '@constants/knack'
import dayNames from '@constants/dayNames'
import WithReportAssets from '@dataProviders/WithReportAssets'
import DailyReportAssignmentCard from '@dailyReport/DailyReportAssignmentCard'
import AssignmentManpower from '@dailyReport/AssignmentManpower'

export default {
  name: 'CardManpower',
  components: {
    WithReportAssets,
    DailyReportAssignmentCard,
    AssignmentManpower,
  },
  data() {
    return {
      assetType: AssetTypes.LABORER,
      fields: [
        {
          title: 'Laborer',
          required: true,
          class: 'flex-6-print',
        },
        'Location',
        'Start Time',
        'End Time',
        'No Lunch?',
        'Total Hours',
        'Reg',
        'OT',
      ],
      isMultiSplitOn: false,
      isShiftItemsModeActive: false,
    }
  },
  computed: {
    ...mapGetters(['getTheReportDate', 'getLaborAssgntField']),
    reportId() {
      return this.$route.params.reportId
    }, // reportId
    reportDate() {
      return dayjs(this.getTheReportDate, 'MM/DD/YYYY').format('MM-DD-YYYY')
    }, // reportDate
    startLocations() {
      const locationStartField = this.getLaborAssgntField('Location Start ')
      const startLocations = _get(locationStartField, 'format.options', [])
      return startLocations
    },
    fieldsWithClasses() {
      let fields = [...this.fieldsWithDTorRegularHours]

      if (!this.isShiftItemsModeActive) {
        fields.push({
          title: 'T&M',
          requiredType: 'number',
          customValidation: {
            operator: '<=',
            fieldToCompare: 'field_272',
            errorMsg: 'The value must not be greater than the total hours.',
          },
        })
      } else {
        let headerName = this.isMultiSplitOn ? 'Split Select' : 'Shift Items'
        fields.push(headerName)
      }

      return fields.map((field) => {
        field = typeof field === 'string' ? { title: field } : field
        return {
          ...field,
          class: field.class ?? 'flex-9-print',
        }
      })
    },
    fieldsWithDTorRegularHours() {
      let fields = [...this.fields]

      if (this.isSundayShift) {
        fields.push('DT')
        let removeItem = function(arr, item) {
          let i = arr.indexOf(item)
          i !== -1 && arr.splice(i, 1)
        }
        // removeItem(fields, 'Reg')
        removeItem(fields, 'OT')
      }
      return fields
    },
    isSundayShift() {
      return (
        dayjs(this.getTheReportDate, 'MM/DD/YYYY').format('dddd') ===
        dayNames.SUNDAY
      )
    },
  }, // computed
  watch: {
    isMultiSplitOn(val) {
      if (!val) {
        this.toggleMultiSplitSelectAll({
          assetType: this.assetType,
          isSelected: false,
        })
      }
    }, // isMultiSplitOn
  },
  methods: {
    ...mapActions(['toggleMultiSplitSelectAll']),
    sortByLaborerType(laborers) {
      return _sortBy(laborers, [
        // Give heavier sort weight to non-foremen
        (l) => (l.LABORERTYPE === LaborerTypes.FOREMAN ? 0 : 1),
        (l) => {
          let parts = l.LABORER_NAME.split(' ')
          return parts[parts.length - 1]
        },
      ])
    }, // sortByLaborerType
    toggleShiftItemsMode() {
      this.isShiftItemsModeActive = !this.isShiftItemsModeActive
      if (!this.isShiftItemsModeActive) {
        this.isMultiSplitOn = false
      }
    }, // toggleShiftItemsMode
    reloadCard() {
      let currentAssetType = this.assetType
      this.assetType = ''
      this.$nextTick(() => {
        this.assetType = currentAssetType
      })
    },
  },
}
</script>

<style scoped>
.split-mode-toggle {
  width: 200px;
}
.multi-toggle-btn {
  width: 135px;
}
</style>
