<template>
  <div>
    <VCard
      :class="[
        'daily-list-item flex-container valign-wrapper my-2',
        { working: asset.isWorking },
        { 'special-material': isSpecialMaterial },
        { draft: asset.draft },
        { modified: hasStagedChanges },
      ]"
      color="grey lighten-2"
    >
      <BaseSpinner
        v-if="asset.isWorking"
        class="working-spinner"
        text-fg-color="#444444"
        size="small"
        :message="asset.draft ? 'Creating Asset...' : 'Saving Asset...'"
        margin="0"
      ></BaseSpinner>
      <VForm
        ref="assgntForm"
        v-model="isValid"
        :class="['layout assgnt--wrap']"
      >
        <slot
          :updateAssetAction="updateAssetAction"
          :startSaveTimer="startSaveTimer"
          :pauseSaveTimer="pauseSaveTimer"
          :draft="draft"
        ></slot>
        <VFlex>
          <VTooltip v-if="!asset.draft" v-model="confirmTrash" top>
            <template v-slot:activator="{ on }">
              <BaseButton
                v-if="canRemove"
                color="red"
                :disabled="asset.isWorking"
                small
                fab
                class="remove-btn no-print elevation-0"
                md-icon="clear"
                @click="removeAction"
              />
            </template>
            <BaseButton
              v-if="canRemove && confirmTrash"
              color="red"
              small
              class="no-print elevation-0"
              @click="removeAction"
              >Are you Sure?</BaseButton
            >
          </VTooltip>
        </VFlex>
      </VForm>
    </VCard>
    <div v-show="isShowingShiftItemList">
      <slot name="shift-items" />
    </div>
  </div>
</template>

<script>
import { AssetTypes } from '@constants/knack'
import { IDLE_COUNTDOWN_MS } from '@constants/appConfig'
import VTooltip from '@vuetify/VTooltip'
import { mapGetters, mapActions } from 'vuex'
import { createHelpers } from 'vuex-map-fields'
import { debounce } from 'lodash'

const { mapFields } = createHelpers({
  getterType: 'getShiftItemsField',
  mutationType: 'updateShiftItemsField',
})
export default {
  name: 'AssignmentItem',
  components: { VTooltip },
  props: {
    canRemove: {
      type: Boolean,
      default: true,
    },
    assetType: {
      type: String,
      required: true,
    },
    assetId: {
      type: String,
      required: true,
    },
    assgntNameKey: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      draft: {},
      confirmTrash: false,
      isValid: false,
      saveTimer: null,
    }
  },
  computed: {
    ...mapGetters([
      'getAssgntById',
      'getIsShiftItemsListVisible',
      'getShiftItemsForShift',
      'getIsSundayShift',
      'getIsSaturdayShift',
    ]),
    ...mapFields(['visibleShiftItemLists']),
    asset() {
      return this.getAssgntById(this.assetType, this.assetId)
    }, // asset
    hasStagedChanges() {
      return Object.keys(this.draft).length
    }, // hasStagedChanges
    isShowingShiftItemList() {
      return this.visibleShiftItemLists.includes(this.assetId)
    },
    shiftItems() {
      return this.getShiftItemsForShift(this.assetType, this.assetId)
    },
    defaultShiftItem() {
      return this.shiftItems.find((si) => si.IS_DEFAULT)
    },
    isSpecialMaterial() {
      return Object.keys(this.draft).includes('IS_SPECIAL_MATERIAL')
        ? this.draft.IS_SPECIAL_MATERIAL
        : this.asset.IS_SPECIAL_MATERIAL
    },
  },
  watch: {
    hasStagedChanges(newNumChanges, oldNumChanges) {
      if (!!newNumChanges === !!oldNumChanges) return
      if (newNumChanges) {
        this.incrementUnsavedAssets()
      } else {
        this.decrementUnsavedAssets()
      }
    },
  },
  methods: {
    ...mapActions([
      'addAsset',
      'updateAsset',
      'replaceAsset',
      'setAssetIsWorking',
      'removeNewAsset',
      'trashAsset',
      'untrashAsset',
      'setAssetModifiedStatus',
      'incrementUnsavedAssets',
      'decrementUnsavedAssets',
      'forceUpdateShiftItem',
    ]),

    async removeAction() {
      if (!this.confirmTrash) {
        this.confirmTrash = true
        setTimeout(() => {
          this.confirmTrash = false
        }, 3000)
      } else {
        this.confirmTrash = false
        let { assetType, assetId, assgntNameKey } = this
        this.trashAsset({ assetType, assetId, assgntNameKey })
      }
    }, // removeAction

    updateAssetAction: debounce(async function(payload) {
      await this.$refs.assgntForm.validate()
      this.draft = { ...this.draft, ...payload }
      if (!this.isValid) {
        return false
      }
      await this.startSaveTimer(IDLE_COUNTDOWN_MS || 8000)
    }, 500), // updateAssetAction

    pauseSaveTimer: debounce(async function() {
      await this.clearSaveTimer()
    }, 100), // pauseSaveTimer

    clearSaveTimer() {
      if (this.saveTimer) {
        clearTimeout(this.saveTimer)
        this.saveTimer = null
      }
    }, // clearSaveTimer

    startSaveTimer: debounce(async function(timeout) {
      timeout ??= IDLE_COUNTDOWN_MS || 7000

      // First things first, clear the existing timeout
      await this.clearSaveTimer()

      // If the assignment form is not valid, or doesn't have changes,
      // short circuit countdown and dont start a new countdown
      if (!this.isValid || !this.hasStagedChanges) {
        return false
      }

      this.saveTimer = setTimeout(() => {
        this.saveIfValid()
      }, timeout)
    }, 200), // startSaveTimer

    async saveDraftAsset() {
      let tempId = this.assetId

      let asset = { ...this.asset, ...this.draft }
      let newAssgnt = await this.addAsset({
        asset,
        assetType: this.assetType,
        assgntNameKey: this.assgntNameKey,
      })
      if (newAssgnt) {
        this.decrementUnsavedAssets()
        await this.replaceAsset({
          assetType: this.assetType,
          recordId: tempId,
          payload: newAssgnt,
        })
        return newAssgnt
      } else {
        return false
      }
    }, // saveDraftAsset

    async setIsWorking() {
      await this.setAssetIsWorking({
        assetType: this.assetType,
        recordId: this.assetId,
      })
    }, // async setIsWorking

    async saveIfValid() {
      if (!this.isValid) {
        return false
      }
      try {
        await this.setIsWorking()
        let savedAsset = false
        if (this.asset.draft) {
          savedAsset = await this.saveDraftAsset()
        } else {
          savedAsset = await this.updateAsset({
            asset: { ...this.draft, ID: this.assetId },
            assetType: this.assetType,
            assgntNameKey: this.assgntNameKey,
          })
          let updatedProps = Object.keys({ ...this.draft })
          if (savedAsset) {
            this.draft = {}
          }

          let timeKeys = ['START_TIME', 'END_TIME', 'NO_LUNCH']
          let wasTimeUpdated = updatedProps.some((key) =>
            timeKeys.includes(key)
          )

          // Return if not a laborer or time was not updated
          if (this.assetType !== AssetTypes.LABORER || !wasTimeUpdated) return

          let {
            REGULAR_HOURS,
            OT,
            DOUBLE_TIME,
          } = await this.calculateComputedHoursFromAsset(savedAsset)
          await this.updateShiftItems(REGULAR_HOURS, OT, DOUBLE_TIME)
        }
      } catch (error) {
        console.error(error)
      }
    }, // saveIfValid

    updateShiftItems(regularHours, otHours, doubleTimeHours) {
      const currentRegularHours = this.shiftItems.reduce(
        (acc, shiftItem) => acc + shiftItem.REGULAR_HOURS,
        0
      )
      const currentOtHours = this.shiftItems.reduce(
        (acc, shiftItem) => acc + shiftItem.OT_HOURS,
        0
      )
      const currentDoubleTimeHours = this.shiftItems.reduce(
        (acc, shiftItem) => acc + shiftItem.DT_HOURS,
        0
      )

      let deltaRegularHours = regularHours - currentRegularHours
      let deltaOtHours = otHours - currentOtHours
      let deltaDoubleTimeHours = doubleTimeHours - currentDoubleTimeHours

      // Sort ShiftItems to update the default one first
      const sortedShiftItems = this.shiftItems.sort((a, b) => {
        return a.IS_DEFAULT ? -1 : b.IS_DEFAULT ? 1 : 0
      })

      sortedShiftItems.forEach((shiftItem) => {
        const isDefaultShiftItem = shiftItem.IS_DEFAULT

        const newRegularHours = Math.min(
          Math.max(
            shiftItem.REGULAR_HOURS + deltaRegularHours,
            isDefaultShiftItem ? 0 : shiftItem.REGULAR_HOURS
          ),
          regularHours
        )
        const newOtHours = Math.max(shiftItem.OT_HOURS + deltaOtHours, 0)
        const newDoubleTimeHours = Math.max(
          shiftItem.DT_HOURS + deltaDoubleTimeHours,
          0
        )

        // Calculate remaining delta for regular and OT hours
        deltaRegularHours -= newRegularHours - shiftItem.REGULAR_HOURS
        deltaOtHours -= newOtHours - shiftItem.OT_HOURS
        deltaDoubleTimeHours -= newDoubleTimeHours - shiftItem.DT_HOURS

        // Only call forceUpdateShiftItem if the hours have changed
        if (
          shiftItem.REGULAR_HOURS !== newRegularHours ||
          shiftItem.OT_HOURS !== newOtHours ||
          shiftItem.DT_HOURS !== newDoubleTimeHours
        ) {
          this.forceUpdateShiftItem({
            assetType: this.assetType,
            shiftItemId: shiftItem.ID, // Using the 'ID' property as mentioned
            payload: {
              REGULAR_HOURS: newRegularHours,
              OT_HOURS: newOtHours,
              DT_HOURS: newDoubleTimeHours,
            },
          })
        }
      })
    },
    calculateComputedHoursFromAsset(asset) {
      let { REGULAR_HOURS, OT, DOUBLE_TIME, TOTAL_HOURS } = asset
      let isSunday = this.getIsSundayShift(this.assetType, asset.ID)
      let isSaturday = this.getIsSaturdayShift(this.assetType, asset.ID)
      if (isSaturday) {
        OT = TOTAL_HOURS
        REGULAR_HOURS = 0
        DOUBLE_TIME = 0
      }
      if (isSunday) {
        OT = 0
        REGULAR_HOURS = 0
        DOUBLE_TIME = TOTAL_HOURS
      }
      return { REGULAR_HOURS, OT, DOUBLE_TIME }
    },
  }, // methods
}
</script>

<style scoped>
.daily-list-item >>> .field-wrap {
  display: flex;
  align-items: center;
}
.daily-list-item
  >>> .field-wrap
  .v-text-field.v-text-field--solo
  .v-input__control {
  min-height: 36px;
}
.daily-list-item .remove-btn >>> .v-icon--right,
.daily-list-item .save-assgnt-btn >>> .v-icon--right {
  margin: 0 !important;
}
</style>

<style lang="scss">
.daily-list-item {
  position: relative;
  padding: $gutter / 2;
  text-align: left;
  background-color: $gray-light;
  border: 2px solid transparent;

  &:not(.cannot-remove) {
    padding-right: $gutter * 2;
  }

  .assgnt--wrap {
    transition: opacity 0.3s ease;
    > .container {
      max-width: none;
    }
    .layout.wrap {
      @media print {
        flex-wrap: nowrap;
        margin: 0;
      }
    }
  }

  &.working {
    justify-content: center;
    .assgnt--wrap {
      opacity: 0.2;
    }
  }
  &.draft,
  &.modified {
    border-left-color: $blue !important;
  }
  &.draft {
    background-color: desaturate(lighten($blue, 35), 55) !important;
  }

  @media #{$mobile} {
    padding-right: 10px;
  }

  textarea {
    padding: 7px 0;
  }
  .daily-list-item--title {
    font-size: 1.1rem;
    font-weight: bold;
    line-height: 1.1;
    color: $gray-dark;

    @media screen and #{$mobile} {
      font-size: 1.4rem;
      line-height: 1.4;
    }
  }
  .picklist-wrap {
    padding-bottom: 15px;
    .daily-list-item--title {
      margin-right: 10px;
    }
  }
  .picklist-btn {
    &.required.invalid {
      background-color: $light-red !important;
      border: none;
      // border:1px solid red;
    }
  }

  &.conditional-field-item {
    padding-left: $gutter / 2;
  }

  .remove-btn,
  .save-assgnt-btn {
    width: 20px;
    min-width: 0 !important;
    height: 20px;
    margin: 0;
  }
  .working-spinner {
    position: absolute;
  }
} // .daily-list-item
</style>
