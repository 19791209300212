<template>
  <BasePrintableField v-if="isPrintModeOn" :value="value" />
  <VTooltip v-else v-model="showTooltipError" top>
    <template v-slot:activator="{ on }">
      <div :class="['wci-picklist', classes]">
        <VAutocomplete
          ref="input"
          v-bind="allBindings"
          :value="value"
          :label="title"
          :items="items"
          :hide-details="!isSmOrLess || !allBindings.hint"
          :persistent-hint="isSmOrLess"
          :item-value="allBindings['item-value'] || 'ID'"
          solo
          flat
          @mouseover.native="showErrors = true"
          @mouseout.native="showErrors = false"
          @click="$emit('picking')"
          @focus="$emit('picking')"
          @blur.self="$emit('donePicking')"
          v-on="$listeners"
        >
          <slot></slot>
        </VAutocomplete>
      </div>
    </template>
    <span>{{ errorText }}</span>
  </VTooltip>
</template>

<script>
import VAutocomplete from '@vuetify/VAutocomplete'
import VTooltip from '@vuetify/VTooltip'
import { mapGetters } from 'vuex'

export default {
  name: 'Picklist',
  components: { VAutocomplete, VTooltip },
  props: {
    ...VAutocomplete.$props,
    title: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    classes: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isMounted: false,
      showErrors: false,
    }
  },
  computed: {
    ...mapGetters(['isPrintModeOn']),
    allBindings() {
      // Need to proxify both props and attrs, for example for showLabels
      return { dense: true, ...this.$props, ...this.$attrs }
    },
    errorBucket() {
      return this.hasErrors ? this.$refs.input.errorBucket : []
    }, // errorBucket
    hasErrors() {
      return !this.isMounted ? false : !!this.$refs.input.errorBucket.length
    }, // hasErrors
    errorText() {
      return this.hasErrors ? this.errorBucket[0] : ''
    }, // errorText
    isSmOrLess() {
      return ['sm', 'xs'].includes(this.$mq)
    }, // isSmOrLess
    showTooltipError() {
      return this.hasErrors && this.showErrors && !this.isSmOrLess
    }, // showTooltipError
  },
  mounted() {
    this.isMounted = true
  },
}
</script>

<style lang="scss" scoped>
@import '@design';
.wci-picklist {
  @media #{$mobile} {
    width: 100%;
  }

  @media print {
    .v-text-field .v-input__control {
      .v-input__slot {
        padding-right: 0;
        padding-left: 0 !important;
      }
    }
  }
}
</style>

<style scoped>
.wci-picklist
  >>> .v-text-field.v-text-field--enclosed
  > .v-input__control
  > .v-input__slot {
  padding: 0 6px;
}

@media print {
  .wci-picklist >>> .v-text-field__details {
    display: none;
  }
}

.wci-picklist >>> .v-autocomplete {
  border: 1px solid transparent;
}
.wci-picklist >>> .v-autocomplete.error--text {
  border-color: #ff5252;
}
</style>
