<template>
  <div class="daily-report">
    <VLayout
      row
      justify-center
      class="quick-links-row grey darken-2 no-print"
      py-2
    >
      <QuickLinks />
    </VLayout>
    <template>
      <VContainer class="no-print" pa-1>
        <VLayout align-center>
          <VFlex>
            <template v-if="canAccessScheduler">
              <BaseButton
                color="grey darken-3"
                small
                md-icon="calendar_today"
                :to="{ path: `/scheduler/?startDate=${reportDate}` }"
                >View in Scheduler</BaseButton
              >
              <BaseButton
                color="blue"
                small
                md-icon="print"
                @click="isPrintModeOn ? printFn() : setPrintMode(true)"
                >{{
                  isPrintModeOn ? 'Print this Report' : 'Setup to Print'
                }}</BaseButton
              >
              <PrintModal :show="isPrintModeOn" />
            </template>
            <FileStorageLinkButton :location="getLocation" />
          </VFlex>
        </VLayout>
      </VContainer>

      <VContainer
        id="daily-report-container"
        row
        :pa-0="!['xs', 'sm', 'md'].includes($mq)"
      >
        <VForm ref="reportForm" v-model="isValid">
          <CardTodaysJobSetup
            v-if="!loadShiftsOnly"
            :report="{ ...report, ...draft }"
            :loading="isReportUpdating"
            :locked="isReportLocked"
            @updateReport="saveReport"
            @startSaveTimer="startSaveTimer"
            @keydown="keyupHandler"
          />
          <WithAllowedJobAssets>
            <div slot-scope="{ areAssetsLoading, allowedAssetsError }">
              <div v-if="allowedAssetsError">Error Loading Assets</div>

              <CardManpower :loading="areAssetsLoading" />
              <CardEquipment :loading="areAssetsLoading" />
            </div>
          </WithAllowedJobAssets>
          <template v-if="!loadShiftsOnly">
            <CardMaterials />

            <CardRequisitionItems v-if="getLocationJobAllowsRequisitionItems" />
            <CardPayWorkItem v-else />
            <CardForemansNotes
              :report="{ ...report, ...draft }"
              :loading="isReportUpdating"
              :locked="isReportLocked"
              @updateReport="saveReport"
              @startSaveTimer="startSaveTimer"
              @markLocationComplete="markLocationComplete"
            />
          </template>
        </VForm>
      </VContainer>

      <div v-if="isAccountant && isReportLocked">
        <h4>This is a locked report and cannot be edited.</h4>
      </div>

      <div v-else class="row no-print">
        <BaseButton
          v-if="isAccountant && isReportLocked"
          :disabled="unlockForNow"
          class="unlock-for-now-btn"
          color="blue"
          >Unlock Report for Now<i class="material-icons right"
            >lock</i
          ></BaseButton
        >
        <BaseButton
          :to="`/locations/${this.$route.params.locationId}`"
          color="cyan"
          md-icon="location_city"
          md-icon-position="left"
          >View Location</BaseButton
        >
        <div v-if="isDispatcher || isProjMgr || isForeman" class="row">
          <BaseButton
            v-if="isReportScheduled && (isDispatcher || isProjMgr)"
            :loading="isReportUpdating"
            class="finalize-setup"
            color="blue darken-1"
            @click="finalizeReportSetup"
            >Finalize Setup</BaseButton
          >
          <BaseButton
            v-else-if="isForeman && (isReportSetup || isReportCompleted)"
            :disabled="!isReportSetup"
            :loading="isReportSetup && isReportUpdating"
            class="finalize-setup"
            color="blue darken-1"
            @click="markReportComplete"
            >{{
              isReportSetup ? 'Complete This Report' : 'Report Is Completed'
            }}</BaseButton
          >
        </div>
        <div class="row">
          <BaseButton
            color="blue darken-1"
            md-icon="print"
            @click="isPrintModeOn ? printFn() : setPrintMode(true)"
            >{{
              isPrintModeOn ? 'Print this Report' : 'Setup to Print'
            }}</BaseButton
          >
        </div>
      </div>
      <VContainer v-if="revisions.length" row>
        <RevisionsList :revisions="revisions" />
      </VContainer>
    </template>
  </div>
</template>

<script>
import { AssetTypes, ReportStatuses, LocationStatuses } from '@constants/knack'
import {
  IDLE_COUNTDOWN_MS,
  LOAD_REPORT_SHIFTS_ONLY,
} from '@constants/appConfig'
import { mapGetters, mapActions } from 'vuex'
import deepDiff from '@utils/deepDiff'
import '@utils/registerSharedDailyReportComponents'
import dayjs from '@utils/dayjsCustomParse'
import debounce from 'debounce'
import PrintModal from '@modals/PrintModal'

import QuickLinks from '@dailyReport/QuickLinks'
import WithAllowedJobAssets from '@dataProviders/WithAllowedJobAssets'
import CardManpower from '@dailyReport/CardManpower'
import CardEquipment from '@dailyReport/CardEquipment'
import CardMaterials from '@dailyReport/CardMaterials'
import CardPayWorkItem from '@dailyReport/CardPayWorkItem'
import CardRequisitionItems from '@dailyReport/CardRequisitionItems'
import CardTodaysJobSetup from '@dailyReport/CardTodaysJobSetup'
import CardForemansNotes from '@dailyReport/CardForemansNotes'
import RevisionsList from '@dailyReport/Revisions/RevisionsList'
import FileStorageLinkButton from '@dailyReport/FileStorageLinkButton'
import { createHelpers } from 'vuex-map-fields'

const { mapFields } = createHelpers({
  getterType: 'getShiftItemsField',
  mutationType: 'updateShiftItemsField',
})
const { mapFields: mapReportFields } = createHelpers({
  getterType: 'getReportField',
  mutationType: 'updateReportField',
})

export default {
  name: 'DailyReport',
  components: {
    QuickLinks,
    WithAllowedJobAssets,
    CardTodaysJobSetup,
    CardManpower,
    CardEquipment,
    CardMaterials,
    CardPayWorkItem,
    CardRequisitionItems,
    CardForemansNotes,
    RevisionsList,
    PrintModal,
    FileStorageLinkButton,
  },
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      draft: {},
      assetTypes: AssetTypes,
      isValid: true,
      isUserEditing: false,
      dirtScore: 0,
      saveTimer: null,
      countdownInterval: null,
      msUntilSave: null,
      loadShiftsOnly: LOAD_REPORT_SHIFTS_ONLY,
      pageLoadTime: Date.now(),
    }
  },
  computed: {
    ...mapGetters([
      'isAccountant',
      'isAdmin',
      'isProjMgr',
      'isDispatcher',
      'isForeman',
      'getTheReport',
      'getTheReportDate',
      'canAccessScheduler',
      'getLocation',
      'getGoogleDriveLink',
      'isPrintModeOn',
      'getNumUnsavedAssets',
      'getLocationJobAllowsRequisitionItems',
    ]),
    ...mapFields(['unsavedShiftItems']),
    ...mapReportFields(['idleSaveCountDownPercentage', 'isReportUpdating']),
    isReportLocked() {
      return this.report.REPORT_STATUS === ReportStatuses.LOCKED
    },
    isReportSetup() {
      return this.report.REPORT_STATUS === ReportStatuses.SETUP
    },
    isReportScheduled() {
      return this.report.REPORT_STATUS === ReportStatuses.SCHEDULED
    },
    isReportCompleted() {
      return this.report.REPORT_STATUS === ReportStatuses.COMPLETED
    },
    revisions() {
      let revisions = this.getTheReport.REVISIONS || []
      if (typeof revisions === 'string' && !revisions.length) {
        return false
      }
      return typeof revisions === 'string' ? JSON.parse(revisions) : revisions
    }, // revisions
    reportDate() {
      return dayjs(this.getTheReportDate, 'MM/DD/YYYY').format('MM-DD-YYYY')
    }, // reportDate
    hasStagedChanges() {
      return !!Object.keys(this.draftDiff).length
    }, // hasStagedChanges
    draftDiff() {
      return deepDiff(this.draft, this.report)
    }, // draftDiff
  },
  watch: {
    report(newValue) {
      this.draft = { ...newValue }
    },
    async isUserEditing(val) {
      if (!this.isValid) {
        return false
      }
      if (!val) {
        this.isReportUpdating = true
        let updatedReport = await this.updateReport(this.draft)

        if (updatedReport) {
          this.draft = {}
        }
        this.isReportUpdating = false
      }
    },
    hasStagedChanges(val) {
      this.updateDirtScore(Number(val))
    },
    getNumUnsavedAssets(val, oldVal) {
      this.updateDirtScore(val - oldVal)
    },
    unsavedShiftItems(val, oldVal) {
      this.updateDirtScore(val - oldVal)
    },
    dirtScore(dirtiness) {
      this.$emit('dirtyUpdate', !!dirtiness)
    },
    msUntilSave(msUntilSave) {
      this.idleSaveCountDownPercentage = (msUntilSave / IDLE_COUNTDOWN_MS) * 100
      if (msUntilSave === 0) {
        this.clearSaveTimerInterval()
        this.msUntilSave = null
        this.$nextTick(() => {
          this.idleSaveCountDownPercentage = null
        })
      }
    },
  }, // watch
  async created() {
    await this.fetchLaborAssgntSchema()
  },
  methods: {
    ...mapActions([
      'updateReport',
      'updateLocation',
      'setPrintMode',
      'fetchLaborAssgntSchema',
    ]),
    async validateReport() {
      let isValid = await this.$refs.reportForm.validate()
      return isValid
    }, // validateReport

    saveReport: debounce(async function({ updates, label }) {
      if (this.isReportLocked) {
        this.$notify({
          title: 'This report is locked and cant be modified',
          type: 'warn',
        })
        return false
      }

      this.isValid = await this.validateReport()
      this.draft = { ...this.draft, ...updates }
      if (!this.isValid) {
        this.$notify({
          type: 'warn',
          title: 'Report Errors',
          text: 'Please fix the errors on the Report before saving.',
        })
        return false
      }

      await this.startSaveTimer(8000)
    }, 200), // saveReport

    async forceSaveReport() {
      await this.clearSaveTimer()
      this.isUserEditing = false
    }, // forceSaveReport

    async startSaveTimer(timeout = 10000) {
      timeout = IDLE_COUNTDOWN_MS || timeout
      // First things first, clear the existing timeout
      await this.clearSaveTimer()

      // If it's not valid, or doesn't have changes,
      // short circuit & dont start a new one
      if (!this.isValid || !this.hasStagedChanges) {
        return false
      }

      // Set UI to show editing state
      this.isUserEditing = true
      this.msUntilSave = timeout

      // Start countdown
      this.saveTimer = setTimeout(() => {
        this.isUserEditing = false
      }, timeout)
      this.resetCountdownInterval()
    }, // startSaveTimer

    pauseSaveTimer() {
      this.clearSaveTimer()
      this.clearSaveTimerInterval()
      this.idleSaveCountDownPercentage = 100
    }, // pauseSaveTimer

    clearSaveTimer() {
      if (this.saveTimer) {
        clearTimeout(this.saveTimer)
        this.saveTimer = null
      }
    }, // clearSaveTimer

    clearSaveTimerInterval() {
      clearInterval(this.countdownInterval)
      this.countdownInterval = null
    }, // clearSaveTimerInterval

    resetCountdownInterval() {
      let COUNTDOWN_INTERVAL_MS = 150
      this.clearSaveTimerInterval()
      this.idleSaveCountDownPercentage = null

      this.countdownInterval = setInterval(() => {
        this.msUntilSave = Math.max(this.msUntilSave - COUNTDOWN_INTERVAL_MS, 0)
      }, COUNTDOWN_INTERVAL_MS)
    }, // resetCountdownInterval

    keyupHandler(e) {
      this.pauseSaveTimer()
    }, // keyupHandler

    async markLocationComplete() {
      this.isReportUpdating = true
      let location = this.getLocation
      await this.updateLocation({
        ID: location.ID,
        STATUS: LocationStatuses.REPORTED_AS_COMPLETE_BY_FOREMAN,
      })
      this.isReportUpdating = false
    }, // markLocationComplete

    async finalizeReportSetup() {
      this.isReportUpdating = true
      let report = {
        ...this.draft,
        REPORT_STATUS: ReportStatuses.SETUP,
      }
      let updatedReport = await this.updateReport(report)
      this.isReportUpdating = false
      return updatedReport
    }, // finalizeReportSetup

    async markReportComplete() {
      const currentTime = Date.now()
      const timeSinceLoad = currentTime - this.pageLoadTime

      if (timeSinceLoad <= 10000) {
        this.isReportUpdating = true
        let report = {
          ...this.draft,
          REPORT_STATUS: ReportStatuses.COMPLETED,
        }
        let updatedReport = await this.updateReport(report)
        this.isReportUpdating = false
        return updatedReport
      } else {
        alert(
          "The page will refresh to so Shifts can be reviewed in case they didn't save properly. Check for red errors, then try completing the Report again within 10 seconds."
        )
        location.reload()
      }
    }, // markReportComplete

    updateDirtScore(delta) {
      if (typeof delta !== 'number') {
        throw new Error('updateDirtScore must be passed a number')
      }
      let change = delta > 0 ? 1 : -1
      this.dirtScore += change
    }, // updateDirtScore
  }, // methods
}
</script>

<style lang="scss" scoped>
.quick-links-row {
  position: sticky;
  top: 64px;
  z-index: 1;
  background-color: #757575;

  @media #{$lt_980} {
    top: 48px;
  }

  @media #{$lt_768} {
    top: 56px;
  }
}

.report-data {
  display: inline-block;
  padding: 10px;
}

.daily-report {
  // &.status-Completed {
  //   background-color:$green;
  // }

  > .container,
  .meta--wrap > .container {
    @media #{$gt_768} {
      width: 98%;
      max-width: 1440px;
      padding-right: 8px;
      padding-left: 8px;
    }
  }

  .meta--wrap > .container {
    @media #{$lt_1024} {
      width: 100%;
    }
  }
}

.save-button {
  &.v-btn--bottom {
    bottom: 24px;
  }
  &.v-btn--right {
    right: 24px;
  }
}

.progress {
  background-color: $gray-dark;
  .determinate {
    background-color: $teal;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.fade-enter-active {
  transition-delay: 0.3s;
}
</style>
