<template>
  <WithReportAssets :asset-type="assetType" :sort-by="'MATERIAL_NAME'">
    <div
      slot-scope="{ reportAssets, areAssignmentsLoading, reportAssetsError }"
    >
      <WithAllAssetsOfType
        asset-type="materialpart"
        :additional-filters="filterByJobTypePicklist"
        filter-match-type="or"
      >
        <div slot-scope="{ allAssets, areAssetsLoading }">
          <DailyReportAssignmentCard
            id="material"
            title="Material"
            :assets="reportAssets"
            :asset-type="assetType"
            :loading="areAssignmentsLoading || areAssetsLoading"
            :error="reportAssetsError"
            :fields="fields"
            :defaults="{
              assetNameKey: 'MATERIALPART_NAME',
              picklistKey: 'MATERIAL_NAME',
              assgntKey: 'MATERIALPART',
            }"
            @reloadCard="reloadCard"
          >
            <template
              slot-scope="{
                asset,
                draft,
                updateAssetAction,
                startSaveTimer,
                pauseSaveTimer,
              }"
            >
              <AssignmentMaterial
                :materials="
                  allAssets.map((m) => {
                    return {
                      ...m,
                      MATERIALPART_NAME: m.MATERIALPART_NAME,
                    }
                  })
                "
                :asset="asset"
                :draft="draft"
                @update="updateAssetAction"
                @startSaveTimer="startSaveTimer"
                @picking="pauseSaveTimer"
                @donePicking="startSaveTimer"
              />
            </template>
          </DailyReportAssignmentCard>
        </div>
      </WithAllAssetsOfType>
    </div>
  </WithReportAssets>
</template>

<script>
import { AssetTypes, HumanFields, AssetObjectNames } from '@constants/knack'
import WithReportAssets from '@dataProviders/WithReportAssets'
import WithAllAssetsOfType from '@dataProviders/WithAllAssetsOfType'
import { mapGetters } from 'vuex'

import DailyReportAssignmentCard from '@dailyReport/DailyReportAssignmentCard'
import AssignmentMaterial from '@dailyReport/AssignmentMaterial'

export default {
  name: 'CardMaterials',
  components: {
    WithReportAssets,
    WithAllAssetsOfType,
    DailyReportAssignmentCard,
    AssignmentMaterial,
  },
  data() {
    return {
      assetType: AssetTypes.MATERIALS,
      fields: [
        {
          title: 'Material/Part',
          required: true,
          class: 'flex-4-print',
        },
        { title: 'Unit Type', class: 'flex-7-print' },
        { title: 'Notes', class: 'flex-7-print' },
        {
          title: 'Sent Out',
          requiredType: 'number',
          class: 'flex-7-print',
        },
        {
          title: 'Used on Site',
          requiredType: 'number',
          class: 'flex-7-print',
        },
        { title: 'T&M?', class: 'flex-7-print checkbox-col' },
        { title: 'Special?', class: 'flex-7-print checkbox-col' },
      ],
    }
  },
  computed: {
    ...mapGetters(['getTheReport']),
    filterByJobTypePicklist() {
      if (!this.assetType) return []

      let locationJobType = this.getTheReport.JOB_TYPE
      let jobTypeParts = locationJobType.split(', ')
      let jobTypeRules = jobTypeParts.map((jobType) => ({
        field:
          HumanFields[AssetObjectNames[this.assetType]]
            .INCLUDE_IN_JOB_TYPE_PICKLIST,
        operator: 'contains',
        value: jobType,
      }))

      return jobTypeRules
    }, // filterByJobTypePicklist
  },
  methods: {
    reloadCard() {
      let currentAssetType = this.assetType
      this.assetType = ''
      this.$nextTick(() => {
        this.assetType = currentAssetType
      })
    },
  },
}
</script>

<style lang="scss">
.checkbox-col {
  @media print {
    align-items: center;
    flex-shrink: 1 !important;
  }
}
</style>
