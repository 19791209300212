var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DailyNotesCard',{attrs:{"id":"foremans-notes","title":"Foreman's Notes","loading":_vm.loading}},[_c('BaseTextarea',_vm._g({attrs:{"value":_vm.report.DESCRIPTION_OF_WORK_COMPLETED_TODAY,"readonly":_vm.locked || _vm.loading,"clearable":"","label":"Describe in Detail the Work Completed Today","rules":[
      (v) =>
        _vm.isReportScheduledStatus ||
        v.length >= 5 ||
        'Please enter a description',
    ]},on:{"input":function($event){return _vm.updateReport(
        { DESCRIPTION_OF_WORK_COMPLETED_TODAY: $event },
        'Describe in Detail the Work Completed Today'
      )},"focus":function($event){return _vm.$emit('startSaveTimer')}}},_vm.$listeners)),_c('BaseTextarea',_vm._g({attrs:{"value":_vm.report.FOREMANS_NOTES,"readonly":_vm.locked || _vm.loading,"label":"Foreman's Notes","clearable":""},on:{"input":function($event){return _vm.updateReport({ FOREMANS_NOTES: $event }, `Foreman's Notes`)},"focus":function($event){return _vm.$emit('startSaveTimer')}}},_vm.$listeners)),_c('BaseTextarea',_vm._g({attrs:{"value":_vm.report.PAYROLL_NOTES,"readonly":_vm.locked || _vm.loading,"label":"Payroll Notes","clearable":""},on:{"input":function($event){return _vm.updateReport({ PAYROLL_NOTES: $event }, `Payroll Notes`)},"focus":function($event){return _vm.$emit('startSaveTimer')}}},_vm.$listeners)),_c('ListItemCheckbox',{attrs:{"value":_vm.hasIncidentOnSite,"readonly":_vm.locked || _vm.loading,"force-show-label":"","inline":"","color":"teal","label":"Accidents/Incidents Today?"},on:{"change":function($event){return _vm.updateReport(
        { INCIDENT_ON_SITE: !!$event },
        'Accidents/Incidents Today?'
      )}}}),_c('transition',{attrs:{"name":"fade-fast"}},[(_vm.hasIncidentOnSite)?_c('BaseTextarea',_vm._g({attrs:{"value":_vm.report.DESCRIPTION_OF_ANY_ACCIDENTSINCIDENTS_TODAY,"readonly":_vm.locked || _vm.loading,"label":"Describe any Accidents/Incidents"},on:{"input":function($event){return _vm.updateReport(
          {
            DESCRIPTION_OF_ANY_ACCIDENTSINCIDENTS_TODAY: $event,
          },
          'Describe any Accidents/Incidents'
        )},"focus":function($event){return _vm.$emit('startSaveTimer')}}},_vm.$listeners)):_vm._e()],1),_c('transition',{attrs:{"name":"fade-fast"}},[(_vm.hasIncidentOnSite)?_c('ListItemCheckbox',{attrs:{"value":_vm.isIncidentReportComplete,"readonly":_vm.locked || _vm.loading,"force-show-label":"","inline":"","color":"teal","label":"Has an incident report been submitted?"},on:{"change":function($event){return _vm.updateReport(
          { INCIDENT_REPORT_COMPLETED: !!$event },
          'Has an incident report been submitted?'
        )}}}):_vm._e()],1),_c('ListItemCheckbox',{attrs:{"value":_vm.hasChangeOfConditions,"readonly":_vm.locked || _vm.loading,"force-show-label":"","inline":"","color":"teal","label":"Change of conditions?"},on:{"change":function($event){return _vm.updateReport(
        { CHANGE_OF_CONDITIONS: !!$event },
        'Change of conditions?'
      )}}}),_c('ListItemCheckbox',{attrs:{"value":_vm.areJobPhotosUploaded,"readonly":_vm.locked || _vm.loading,"force-show-label":"","inline":"","color":"teal","label":"Job Photos Uploaded?"},on:{"change":function($event){return _vm.updateReport({ JOB_PHOTOS_UPLOADED: !!$event }, 'Job Photos Uploaded?')}}}),_c('ListItemCheckbox',{attrs:{"value":_vm.areAsBuiltsUploaded,"readonly":_vm.locked || _vm.loading,"force-show-label":"","inline":"","color":"teal","label":"As-built Uploaded?"},on:{"change":function($event){return _vm.updateReport({ ASBUILT_UPLOADED: !!$event }, 'As-built Uploaded?')}}}),(_vm.hasChangeOfConditions)?_c('BaseInput',_vm._g({attrs:{"readonly":_vm.locked || _vm.loading,"value":_vm.report.CHANGE_OF_CONDITIONS_NOTES,"label":"Change of Conditions Notes"},on:{"input":function($event){return _vm.updateReport(
        {
          CHANGE_OF_CONDITIONS_NOTES: $event,
        },
        'Change of Conditions Notes'
      )},"focus":function($event){return _vm.$emit('startSaveTimer')}}},_vm.$listeners)):_vm._e(),(_vm.showMarkAsCompleteButton)?_c('VLayout',{staticClass:"no-print",attrs:{"row":""}},[_c('BaseButton',{staticClass:"ml-0",attrs:{"disabled":_vm.isMarkedAsComplete,"small":"","color":"blue"},on:{"click":function($event){return _vm.$emit('markLocationComplete')}}},[_vm._v(_vm._s(_vm.isMarkedAsComplete ? 'Location is Marked As Complete' : 'Mark this Location As Complete'))])],1):_vm._e(),(!_vm.isMarkedAsComplete)?_c('VLayout',{staticClass:"no-print text-xs-left",staticStyle:{"max-width":"400px","line-height":"1.3"},attrs:{"row":""}},[_c('p',[_vm._v("Only click \"Mark Location As Complete\" if this Job is 100% complete except for Paving and Cleanup")])]):_vm._e(),_c('VLayout',{staticClass:"no-print",attrs:{"row":""}},[_c('FileStorageLinkButton',{attrs:{"location":_vm.getLocation}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }