<template>
  <VLayout row :wrap="['xs', 'sm'].includes($mq)" class="manpower-assignment">
    <VFlex
      :class="[
        isPrintModeOn
          ? 'flex-6-print flex-6-sm flex-6-md flex-1-xs flex-item field-wrap'
          : flexStyles,
        'daily-list-item--title',
      ]"
    >
      <ListItemDisplayOnly
        style="word-break:break-word;"
        :style="isAdmin ? 'cursor:pointer;' : ''"
        :disabled="disabled"
        :value="asset.LABORER_NAME"
        @click.native="editLaborAssignmentInKnack"
      />
    </VFlex>
    <VFlex :class="flexStyles">
      <BaseSelect
        :title="asset.LOCATION_START_"
        :value="asset.LOCATION_START_"
        :items="startLocations"
        :disabled="disabled"
        classes="location-select"
        hint="Location"
        v-on="$listeners"
        @input="$emit('update', { LOCATION_START_: $event })"
      />
    </VFlex>
    <VFlex :class="flexStyles">
      <ListItemTimepicker
        flat
        solo
        label="Start Time"
        hint="Start Time"
        :disabled="disabled"
        :value="asset.START_TIME ? asset.START_TIME.timestamp : ''"
        v-on="$listeners"
        @change="updateTime('START_TIME', $event)"
      />
    </VFlex>
    <VFlex :class="flexStyles">
      <ListItemTimepicker
        flat
        solo
        label="End Time"
        hint="End Time"
        :disabled="disabled"
        :value="asset.END_TIME ? asset.END_TIME.timestamp : ''"
        @change="updateTime('END_TIME', $event)"
        v-on="$listeners"
      />
    </VFlex>
    <VFlex :class="flexStyles">
      <ListItemCheckbox
        :disabled="disabled"
        :value="asset.NO_LUNCH"
        label="No Lunch?"
        hide-details
        @change="updateNoLunch"
      />
    </VFlex>
    <VFlex :class="flexStyles">
      <ListItemAssignmentHoursDisplay
        :value="totalHours"
        hint="Total Hours"
        :delta="totalHoursDelta"
      />
    </VFlex>
    <VFlex :class="flexStyles">
      <ListItemAssignmentHoursDisplay
        :value="regularHours"
        hint="Regular Hours"
        :delta="regularHoursDelta"
      />
    </VFlex>
    <VFlex :class="flexStyles">
      <ListItemAssignmentHoursDisplay
        v-if="isSundayShift"
        :value="doubleTimeHours"
        hint="Overtime"
        :delta="doubleTimeHoursDelta"
      />
      <ListItemAssignmentHoursDisplay
        v-else
        :value="overtimeHours"
        hint="Overtime"
        :delta="overtimeHoursDelta"
      />
    </VFlex>
    <ShiftItemActions
      v-if="isShiftItemsModeActive && !isMultiSplitOn"
      key="split-btn"
      :class="flexStyles"
      :shift-items="shiftItems"
      :is-list-visible="isShiftItemsListVisible"
      :disabled="isSplittingDisabled"
      :is-working="asset.isWorking"
      @add="addShiftItem"
      @toggle="toggleShiftItemsListVisibility(shiftId)"
    />
    <VFlex
      v-else-if="isShiftItemsModeActive && isMultiSplitOn"
      key="multi-split-checkbox"
      :class="flexStyles"
    >
      <ListItemCheckbox
        :value="isMultiSplitSelected"
        small
        color="blue"
        class="no-print"
        hide-details
        @change="
          toggleMultiSplitSelection({ assetType, shiftId, isSelected: $event })
        "
      />
    </VFlex>
    <VFlex v-else key="t-and-m" :class="flexStyles">
      <ListItemText
        :disabled="disabled"
        :value="asset.NUMBER_OF_HOURS_FOR_TIME__MATERIALS_BILLING"
        hint="T&amp;M"
        :rules="tmRules"
        @input="
          $emit('update', {
            NUMBER_OF_HOURS_FOR_TIME__MATERIALS_BILLING: Number($event),
          })
        "
        @focus="$emit('startSaveTimer')"
      />
    </VFlex>
  </VLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import _isNaN from 'lodash/isNaN'
import _get from 'lodash/get'
import { AssetTypes, ContentTypes } from '@constants/knack'
import getTimeInMinutes from '@utils/getTimeInMinutes'

import ShiftItemActions from '@components/DailyReportView/ShiftItemActions'

import FlexStylesMixin from '@mixins/flex-styles'

export default {
  name: 'AssignmentManpower',
  components: {
    ShiftItemActions,
  },
  mixins: [FlexStylesMixin(9)],
  props: {
    asset: {
      type: Object,
      required: true,
      default: () => {},
    },
    draft: {
      type: Object,
      required: true,
      default: () => {},
    },
    startLocations: {
      type: Array,
      required: true,
      default: () => [],
    },
    isShiftItemsModeActive: {
      type: Boolean,
      required: true,
      default: false,
    },
    isMultiSplitOn: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      assetType: AssetTypes.LABORER,
      tmRules: [
        (v) => {
          return !isNaN(Number(v)) || 'You must enter a number'
        },
        (v) => v <= this.totalHours || 'T&M Hours cannot exceed Total Hours',
      ],
      laborAssignmentsObjectId: ContentTypes.LABOR_ASSIGNMENT,
    }
  },
  computed: {
    ...mapGetters([
      'isPrintModeOn',
      'getIsShiftItemsListVisible',
      'getLocation',
      'getShiftItemsForShift',
      'isAdmin',
      'getIsSundayShift',
      'getIsSaturdayShift',
      'getIsMultiSplitSelected',
    ]),
    shiftId() {
      return this.asset.ID
    },
    totalHours() {
      let totalHours = this.timeWorkedMinutes / 60

      // If they took lunch, subtract 30min
      if (this.hadLunch) {
        totalHours = totalHours > 0.5 ? totalHours - 0.5 : 0
      }

      totalHours = _isNaN(totalHours) ? 0 : parseFloat(totalHours)

      return +totalHours
    }, // totalHours

    regularHours() {
      let regularHours
      if (this.isSundayShift || this.isSaturdayShift) {
        regularHours = 0
      } else {
        regularHours = this.totalHours > 8 ? 8 : this.totalHours
      }
      return +regularHours.toFixed(2)
    }, // regularHours

    overtimeHours() {
      let overtimeHours
      if (this.isSundayShift) {
        // All Sunday hours are DT so return 0 for OT
        overtimeHours = 0
      } else if (this.isSaturdayShift) {
        return (overtimeHours = this.totalHours)
      } else {
        overtimeHours = Math.max(this.totalHours - 8, 0)
      }

      return +overtimeHours.toFixed(2)
    },

    doubleTimeHours() {
      let dt = this.isSundayShift
        ? this.totalHours
        : _get(this.asset, 'DT_HOURS', 0)
      return +dt.toFixed(2)
    },

    startTimeMinutes() {
      let time = _get(this.draft, 'START_TIME', this.asset.START_TIME)
      return getTimeInMinutes(time)
    }, // startTimeMinutes

    endTimeMinutes() {
      let time = _get(this.draft, 'END_TIME', this.asset.END_TIME)
      return getTimeInMinutes(time)
    }, // endTimeMinutes

    timeWorkedMinutes() {
      let minutes
      if (
        this.startTimeMinutes === false ||
        this.endTimeMinutes === false ||
        _isNaN(this.startTimeMinutes) ||
        _isNaN(this.endTimeMinutes)
      ) {
        return 0
      }

      if (this.endTimeMinutes < this.startTimeMinutes) {
        minutes = this.endTimeMinutes - this.startTimeMinutes + 60 * 24
      } else {
        minutes = this.endTimeMinutes - this.startTimeMinutes
      }
      return minutes
    }, // timeWorkedMinutes

    hadLunch() {
      return !_get(this.draft, 'NO_LUNCH', this.asset.NO_LUNCH)
    }, // hadLunch

    disabled() {
      return this.draft.isWorking || false
    }, // disabled

    shiftItems() {
      return this.getShiftItemsForShift(this.assetType, this.shiftId) || []
    },
    isSplittingDisabled() {
      return !_get(this.asset, 'START_TIME') || !_get(this.asset, 'END_TIME')
    },
    isShiftItemsListVisible() {
      return this.getIsShiftItemsListVisible(this.shiftId)
    },
    totalShiftItemHours() {
      return (
        this.totalShiftItemHoursReg +
        this.totalShiftItemHoursOT +
        this.totalShiftItemHoursDT
      )
    },
    totalShiftItemHoursReg() {
      return +this.shiftItems.reduce((acc, shiftItem) => {
        return acc + shiftItem.REGULAR_HOURS
      }, 0)
    },
    totalShiftItemHoursOT() {
      return +this.shiftItems.reduce((acc, shiftItem) => {
        return acc + shiftItem.OT_HOURS
      }, 0)
    },
    totalShiftItemHoursDT() {
      return +this.shiftItems.reduce((acc, shiftItem) => {
        return acc + shiftItem.DT_HOURS
      }, 0)
    },
    regularHoursDelta() {
      if (!this.shiftItems.length || this.isSundayShift) {
        return 0
      }
      return this.totalShiftItemHoursReg - this.regularHours
    },
    overtimeHoursDelta() {
      if (!this.shiftItems.length || this.isSundayShift) {
        return 0
      }
      return this.totalShiftItemHoursOT - this.overtimeHours
    },
    doubleTimeHoursDelta() {
      if (!this.shiftItems.length) {
        return 0
      }
      return this.totalShiftItemHoursDT - this.doubleTimeHours
    },
    totalHoursDelta() {
      if (!this.shiftItems.length) {
        return 0
      }
      return this.totalShiftItemHours - this.totalHours
    },
    hasShiftItemHoursError() {
      if (!this.shiftItems.length) {
        return false
      }
      return (
        this.regularHoursDelta +
          this.overtimeHoursDelta +
          this.totalHoursDelta !==
        0
      )
    },
    isSundayShift() {
      return this.getIsSundayShift(this.assetType, this.asset.ID)
    },
    isSaturdayShift() {
      return this.getIsSaturdayShift(this.assetType, this.asset.ID)
    },
    isMultiSplitSelected() {
      return this.getIsMultiSplitSelected(this.assetType, this.asset.ID)
    },
  }, // computed
  methods: {
    ...mapActions([
      'addDraftShiftItem',
      'toggleShiftItemsListVisibility',
      'toggleMultiSplitSelection',
    ]),
    updateTime(key, $event) {
      this.$emit('update', { [key]: $event })
    }, // updateTime
    updateNoLunch($event) {
      this.$emit('update', { NO_LUNCH: $event })
    }, // updateNoLunch

    addShiftItem() {
      if (!this.getIsShiftItemsListVisible(this.shiftId)) {
        this.toggleShiftItemsListVisibility(this.shiftId)
      }
      let currentShiftItems =
        this.getShiftItemsForShift(this.assetType, this.shiftId) || []

      let regularHours = !currentShiftItems.length ? this.regularHours : 0
      let overtimeHours = !currentShiftItems.length ? this.overtimeHours : 0
      let doubleTimeHours = !currentShiftItems.length ? this.doubleTimeHours : 0
      let location = this.getLocation
      let defaultPhase = _get(location, 'DEFAULT_PHASE[0]')
      let defaultCostCode = _get(location, 'DEFAULT_COST_CODE[0]')
      let newShiftItem = {
        PHASE: [defaultPhase],
        COST_CODE: [defaultCostCode],
        REGULAR_HOURS: regularHours,
        OT_HOURS: overtimeHours,
        DT_HOURS: doubleTimeHours,
        IS_DEFAULT: !currentShiftItems.length,
      }
      this.addDraftShiftItem({
        assetType: this.assetType,
        shiftId: this.shiftId,
        shiftItem: newShiftItem,
      })
    },
    editLaborAssignmentInKnack() {
      if (!this.isAdmin) return false
      window.open(
        `https://builder.knack.com/westmoreland/projectmgmt/records/objects/${this.laborAssignmentsObjectId}/record/${this.asset.ID}/edit`
      )
    },
  }, // methods
}
</script>

<style lang="scss">
.location-select {
  &.v-text-field.v-text-field--enclosed .v-input__slot {
    padding: 0 6px;
  }
  .v-select__selection {
    font-size: 14px;
  }
}
</style>
