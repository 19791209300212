var render = function render(){var _vm=this,_c=_vm._self._c;return _c('WithReportAssets',{attrs:{"asset-type":_vm.assetType,"sort-by":'MATERIAL_NAME'},scopedSlots:_vm._u([{key:"default",fn:function({ reportAssets, areAssignmentsLoading, reportAssetsError }){return _c('div',{},[_c('WithAllAssetsOfType',{attrs:{"asset-type":"materialpart","additional-filters":_vm.filterByJobTypePicklist,"filter-match-type":"or"},scopedSlots:_vm._u([{key:"default",fn:function({ allAssets, areAssetsLoading }){return _c('div',{},[_c('DailyReportAssignmentCard',{attrs:{"id":"material","title":"Material","assets":reportAssets,"asset-type":_vm.assetType,"loading":areAssignmentsLoading || areAssetsLoading,"error":reportAssetsError,"fields":_vm.fields,"defaults":{
            assetNameKey: 'MATERIALPART_NAME',
            picklistKey: 'MATERIAL_NAME',
            assgntKey: 'MATERIALPART',
          }},on:{"reloadCard":_vm.reloadCard},scopedSlots:_vm._u([{key:"default",fn:function({
              asset,
              draft,
              updateAssetAction,
              startSaveTimer,
              pauseSaveTimer,
            }){return [_c('AssignmentMaterial',{attrs:{"materials":allAssets.map((m) => {
                  return {
                    ...m,
                    MATERIALPART_NAME: m.MATERIALPART_NAME,
                  }
                }),"asset":asset,"draft":draft},on:{"update":updateAssetAction,"startSaveTimer":startSaveTimer,"picking":pauseSaveTimer,"donePicking":startSaveTimer}})]}}],null,true)})],1)}}],null,true)})],1)}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }