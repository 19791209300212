var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('VCard',{class:[
      'daily-list-item flex-container valign-wrapper my-2',
      { working: _vm.asset.isWorking },
      { 'special-material': _vm.isSpecialMaterial },
      { draft: _vm.asset.draft },
      { modified: _vm.hasStagedChanges },
    ],attrs:{"color":"grey lighten-2"}},[(_vm.asset.isWorking)?_c('BaseSpinner',{staticClass:"working-spinner",attrs:{"text-fg-color":"#444444","size":"small","message":_vm.asset.draft ? 'Creating Asset...' : 'Saving Asset...',"margin":"0"}}):_vm._e(),_c('VForm',{ref:"assgntForm",class:['layout assgnt--wrap'],model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_vm._t("default",null,{"updateAssetAction":_vm.updateAssetAction,"startSaveTimer":_vm.startSaveTimer,"pauseSaveTimer":_vm.pauseSaveTimer,"draft":_vm.draft}),_c('VFlex',[(!_vm.asset.draft)?_c('VTooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(_vm.canRemove)?_c('BaseButton',{staticClass:"remove-btn no-print elevation-0",attrs:{"color":"red","disabled":_vm.asset.isWorking,"small":"","fab":"","md-icon":"clear"},on:{"click":_vm.removeAction}}):_vm._e()]}}],null,false,3580146598),model:{value:(_vm.confirmTrash),callback:function ($$v) {_vm.confirmTrash=$$v},expression:"confirmTrash"}},[(_vm.canRemove && _vm.confirmTrash)?_c('BaseButton',{staticClass:"no-print elevation-0",attrs:{"color":"red","small":""},on:{"click":_vm.removeAction}},[_vm._v("Are you Sure?")]):_vm._e()],1):_vm._e()],1)],2)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowingShiftItemList),expression:"isShowingShiftItemList"}]},[_vm._t("shift-items")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }