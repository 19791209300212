var render = function render(){var _vm=this,_c=_vm._self._c;return _c('WithReportAssets',{attrs:{"asset-type":_vm.assetType,"sort-by":'TABLE_ORDER_NUMBER'},scopedSlots:_vm._u([{key:"default",fn:function({ reportAssets, areAssignmentsLoading, reportAssetsError }){return _c('div',{},[(_vm.getLocationJobId)?_c('WithAllowedJobAssets',{attrs:{"asset-type":_vm.costCodeAssetType,"job-id":_vm.getLocationJobId,"is-for-picklist":true},scopedSlots:_vm._u([{key:"default",fn:function({ assets, areAssetsLoading, allowedAssetsError }){return _c('div',{},[_c('DailyReportAssignmentCard',{attrs:{"id":"pay-work","title":"Requisition Items","assets":reportAssets,"asset-type":_vm.assetType,"fields":_vm.fields,"loading":areAssignmentsLoading || areAssetsLoading,"error":reportAssetsError || allowedAssetsError,"defaults":{
            assetNameKey: 'COST_CODE',
            picklistKey: 'COST_CODE',
            assgntKey: 'COST_CODE',
          }},on:{"reloadCard":_vm.reloadCard},scopedSlots:_vm._u([{key:"default",fn:function({
              asset,
              draft,
              updateAssetAction,
              startSaveTimer,
              pauseSaveTimer,
            }){return [_c('AssignmentRequisitionItem',{attrs:{"pickable-items":assets,"asset":asset,"draft":draft},on:{"update":updateAssetAction,"startSaveTimer":startSaveTimer,"picking":pauseSaveTimer,"donePicking":startSaveTimer}})]}},{key:"add-button",fn:function({ addAction, isAdding, loadingDefaults }){return [_c('BaseButton',{staticClass:"no-print add-record-btn",attrs:{"md-icon":"control_point","color":"blue","loading":isAdding,"disabled":loadingDefaults},on:{"click":function($event){return _vm.addItemHandler(reportAssets, addAction)}}},[_vm._v("New Requisition Item")])]}}],null,true)})],1)}}],null,true)}):_vm._e()],1)}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }