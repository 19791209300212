var render = function render(){var _vm=this,_c=_vm._self._c;return _c('WithReportAssets',{attrs:{"asset-type":_vm.assetType,"sort-by":'TABLE_ORDER_NUMBER'},scopedSlots:_vm._u([{key:"default",fn:function({ reportAssets, areAssignmentsLoading, reportAssetsError }){return _c('div',{},[_c('WithAllAssetsOfType',{attrs:{"asset-type":"paywork_item","additional-filters":_vm.filterByJobTypePicklist,"filter-match-type":"or"},scopedSlots:_vm._u([{key:"default",fn:function({ allAssets, areAssetsLoading }){return _c('div',{},[_c('DailyReportAssignmentCard',{attrs:{"id":"pay-work","title":"Pay/Work Items","assets":reportAssets,"asset-type":_vm.assetType,"fields":_vm.fields,"loading":areAssignmentsLoading || areAssetsLoading,"error":reportAssetsError,"defaults":{
            assetNameKey: 'PAYWORK_ITEM_NAME',
            picklistKey: 'PAYWORK_ITEM_NAME',
            assgntKey: 'PAYWORK_ITEM',
          }},on:{"reloadCard":_vm.reloadCard},scopedSlots:_vm._u([{key:"default",fn:function({
              asset,
              draft,
              updateAssetAction,
              startSaveTimer,
              pauseSaveTimer,
            }){return [_c('AssignmentPayWorkItem',{attrs:{"pickable-items":allAssets,"asset":asset,"draft":draft},on:{"update":updateAssetAction,"startSaveTimer":startSaveTimer,"picking":pauseSaveTimer,"donePicking":startSaveTimer}})]}},{key:"add-button",fn:function({ addAction, isAdding, loadingDefaults }){return [_c('BaseButton',{staticClass:"no-print add-record-btn",attrs:{"md-icon":"control_point","color":"blue","loading":isAdding,"disabled":loadingDefaults},on:{"click":function($event){return _vm.addItemHandler(reportAssets, addAction)}}},[_vm._v("New Pay/Work Item")])]}}],null,true)})],1)}}],null,true)})],1)}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }