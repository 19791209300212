<template>
  <VLayout row wrap class="equipment-assignment">
    <VFlex
      :class="[
        flexStyles,
        'daily-list-item--title',
        'flex-grow-print flex-3_12-print',
      ]"
    >
      <ListItemDisplayOnly :value="asset.EQUIPMENT_NAME" />
    </VFlex>
    <VFlex :class="[flexStyles, 'flex-grow-print flex-3_12-print']">
      <BasePicklist
        :disabled="disabled"
        :title="asset.LABORER_NAME"
        :value="asset.LABORER_NAME"
        :items="operators"
        return-object
        clearable
        hint="Operator / Driver"
        item-text="[0].identifier"
        @click:clear="$emit('update', { LABORER: null })"
        v-on="$listeners"
        @input="$emit('update', { LABORER: $event[0].id })"
      />
    </VFlex>
    <VFlex :class="[flexStyles, 'flex-grow-print flex-5_12-print']">
      <ListItemText
        :disabled="disabled"
        :value="asset.EQUIPMENT_NOTES"
        hint="Equip. Notes"
        @input="
          $emit('update', {
            EQUIPMENT_NOTES: $event,
          })
        "
        @focus="$emit('editing')"
        @keydown="$emit('editing')"
      />
    </VFlex>
    <VFlex :class="[flexStyles, 'flex-1_12-print']">
      <ListItemAssignmentHoursDisplay
        :value="totalHours"
        hint="Total Hours"
        :delta="totalHoursDelta"
      />
    </VFlex>
    <VFlex :class="[flexStyles, 'flex-1_12-print']">
      <ListItemCheckbox
        :disabled="disabled"
        :value="asset.INCLUDE_IN_TIME__MATERIALS_BILLING"
        label="T&amp;M?"
        @change="
          $emit('update', { INCLUDE_IN_TIME__MATERIALS_BILLING: $event })
        "
      />
    </VFlex>
    <ShiftItemActions
      v-if="isShiftItemsModeActive && !isMultiSplitOn"
      key="split-btn"
      :class="[flexStyles, 'flex-1_12-print']"
      :shift-items="shiftItems"
      :is-list-visible="isShiftItemsListVisible"
      :is-working="asset.isWorking"
      @add="addShiftItem"
      @toggle="toggleShiftItemsListVisibility(shiftId)"
    />
    <VFlex
      v-else-if="isShiftItemsModeActive && isMultiSplitOn"
      key="multi-split-checkbox"
      :class="flexStyles"
    >
      <ListItemCheckbox
        :value="isMultiSplitSelected"
        small
        color="blue"
        class="no-print"
        hide-details
        @change="
          toggleMultiSplitSelection({ assetType, shiftId, isSelected: $event })
        "
      />
    </VFlex>
    <VFlex v-else :class="[flexStyles, 'flex-1_12-print']">
      <ListItemCheckbox
        :disabled="disabled"
        :value="asset.STANDBY"
        label="Stanbdy?"
        @change="$emit('update', { STANDBY: $event })"
      />
    </VFlex>
  </VLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { LaborerLocations, AssetTypes } from '@constants/knack'
import FlexStylesMixin from '@mixins/flex-styles'
import _get from 'lodash/get'
import getTimeInMinutes from '@utils/getTimeInMinutes'

import ShiftItemActions from '@components/DailyReportView/ShiftItemActions'

export default {
  name: 'AssignmentEquipment',
  components: {
    ShiftItemActions,
  },
  mixins: [FlexStylesMixin(6)],
  props: {
    asset: {
      type: Object,
      required: true,
      default: () => {},
    },
    operators: {
      type: Array,
      required: true,
      default: () => [],
    },
    draft: {
      type: Object,
      required: true,
      default: () => {},
    },
    isShiftItemsModeActive: {
      type: Boolean,
      required: true,
      default: false,
    },
    isMultiSplitOn: {
      type: Boolean,
      required: true,
      default: false,
    },
  }, // props
  data() {
    return {
      assetType: AssetTypes.EQUIPMENT,
      LaborerLocations,
      totalHours: 8,
      regularHours: 8,
      overtimeHours: 0,
    }
  },
  computed: {
    ...mapGetters([
      'getIsShiftItemsListVisible',
      'getShiftItemsForShift',
      'getLocation',
      'getIsMultiSplitSelected',
    ]),
    shiftId() {
      return this.asset.ID
    },
    disabled() {
      return this.draft.isWorking || false
    }, // disabled
    shiftItems() {
      return this.getShiftItemsForShift(this.assetType, this.shiftId) || []
    },
    isShiftItemsListVisible() {
      return this.getIsShiftItemsListVisible(this.shiftId)
    },

    startTimeMinutes() {
      let time = _get(this.draft, 'START_TIME', this.asset.START_TIME)
      return getTimeInMinutes(time)
    }, // startTimeMinutes

    endTimeMinutes() {
      let time = _get(this.draft, 'END_TIME', this.asset.END_TIME)
      return getTimeInMinutes(time)
    }, // endTimeMinutes

    totalShiftItemHours() {
      return this.totalShiftItemHoursReg + this.totalShiftItemHoursOT
    },
    totalShiftItemHoursReg() {
      return +this.shiftItems.reduce((acc, shiftItem) => {
        return acc + shiftItem.REGULAR_HOURS
      }, 0)
    },
    totalShiftItemHoursOT() {
      return +this.shiftItems.reduce((acc, shiftItem) => {
        return acc + shiftItem.OT_HOURS
      }, 0)
    },
    regularHoursDelta() {
      if (!this.shiftItems.length) {
        return 0
      }
      return this.totalShiftItemHoursReg - this.regularHours
    },
    overtimeHoursDelta() {
      if (!this.shiftItems.length) {
        return 0
      }
      return this.totalShiftItemHoursOT - this.overtimeHours
    },
    totalHoursDelta() {
      if (!this.shiftItems.length) {
        return 0
      }
      return this.totalShiftItemHours - this.totalHours
    },
    hasShiftItemHoursError() {
      if (!this.shiftItems.length) {
        return false
      }
      return (
        this.regularHoursDelta +
          this.overtimeHoursDelta +
          this.totalHoursDelta !==
        0
      )
    },
    isMultiSplitSelected() {
      return this.getIsMultiSplitSelected(this.assetType, this.asset.ID)
    },
  },
  watch: {
    hasShiftItemHoursError: {
      handler: function(hasShiftItemHoursError) {
        this.$emit('mismatchedHours', hasShiftItemHoursError)
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions([
      'addDraftShiftItem',
      'toggleShiftItemsListVisibility',
      'toggleMultiSplitSelection',
    ]),

    addShiftItem() {
      if (!this.getIsShiftItemsListVisible(this.shiftId)) {
        this.toggleShiftItemsListVisibility(this.shiftId)
      }
      let currentShiftItems = this.getShiftItemsForShift(
        this.assetType,
        this.shiftId
      )

      let regularHours = !currentShiftItems.length ? 8 : 0
      let location = this.getLocation
      let defaultPhase = _get(location, 'DEFAULT_PHASE[0]')
      let defaultCostCode = _get(location, 'DEFAULT_COST_CODE[0]')
      let newShiftItem = {
        PHASE: [defaultPhase],
        COST_CODE: [defaultCostCode],
        REGULAR_HOURS: regularHours,
        OT_HOURS: 0,
        IS_DEFAULT: !currentShiftItems.length,
      }
      this.addDraftShiftItem({
        assetType: this.assetType,
        shiftId: this.shiftId,
        shiftItem: newShiftItem,
      })
    },
  },
}
</script>
